<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ TASARIM
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ TASARIM" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-6">
							<div class="field p-fluid">
								<label for="ekip">Ekip</label>
								<MultiSelect v-model="selectedMobilKullanicilar" :options="mobilKullanicilarData" optionLabel="name" placeholder="Ekip seçiniz" :filter="true" class="multiselect-custom">
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{option.name}}</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Ekip seçiniz
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{slotProps.option.name}}</div>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="link">Link</label>
								<InputText id="link" type="text" v-model="mainData.bm_link" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" :disabled="isDevreDisi" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>TARİH BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_tarih">Tarih <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_tarih" v-model="mainData.bm_tarih" autocomplete="off" :showIcon="true"  :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_tarih.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_tarih.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_termintarihi">Termin Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_termintarihi" v-model="mainData.bm_termintarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_termintarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_termintarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_tamamlanmatarihi">Tamamlanma Tarihi</label>
								<Calendar showButtonBar id="bm_tamamlanmatarihi" v-model="mainData.bm_tamamlanmatarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_xgunoncehatirlat">x Gün Önce Hatırlat</label>
								<Dropdown id="bm_xgunoncehatirlat" v-model="mainData.bm_xgunoncehatirlat" :options="SM_bm_xgunoncehatirlat" optionLabel="Value" optionValue="AttributeValue" placeholder="x gün Önce Hatırlat" :showClear="true" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10048,
			ENTITY_NAME: 'bm_otomasyontasarim',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			mobilKullanicilarData: [],
			selectedMobilKullanicilar: null,

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		let mobilKullaniciDataTemp = await this.crmService.GetMobilKullanicilarOtomasyon();
		debugger;
		if (mobilKullaniciDataTemp != null) {
			this.mobilKullanicilarData = mobilKullaniciDataTemp.jsonData;
		}
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		otomasyonProjesiId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		SM_bm_ilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ilerlemedurumu');
		},
		SM_bm_xgunoncehatirlat: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_xgunoncehatirlat');
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					if (this.selectedMobilKullanicilar) {
						if (this.selectedMobilKullanicilar.length > 0) {
							this.mainData["ekip"] = this.selectedMobilKullanicilar;
						}
					}

					this.mainData["bm_otomasyonprojesiId"] = this.otomasyonProjesiId;
					const response = await this.crmService.getOtomasyonTasarimCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 500);
							}
							else {
								setTimeout(() => {
									this.$router.push({ name: this.ENTITY_NAME, params: { id: response.olusturulanId } });
								}, 500);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_tarih: {
					required : helpers.withMessage('Tarih alanı dolu olmalıdır!', required),
				},
				bm_termintarihi: {
					required : helpers.withMessage('Termin Tarihi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.stickBar {
		position: sticky;
		top: 6.2rem;
		z-index: 10;
	}

	.country-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	::v-deep(.multiselect-custom) {
		.p-multiselect-label:not(.p-placeholder) {
			padding-top: .50rem;
			padding-bottom: .50rem;
		}

		.country-item-value {
			padding: .25rem .5rem;
			border-radius: 3px;
			display: inline-flex;
			margin-right: .5rem;
			background-color: var(--primary-color);
			color: var(--primary-color-text);

			img.flag {
				width: 17px;
			}
		}
	}

	@media screen and (max-width: 640px) {
		.p-multiselect {
			width: 100%;
		}
	}


</style>
